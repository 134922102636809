function initCategories() {
  const $blocks = $('.block-categories');

  $blocks.each((i, block) => {
    const itemWrappers = $(block).find('.item-wrapper');
    const blockWrappers = $(block).find('.block-wrapper');
    itemWrappers.hide();

    blockWrappers.each((i, wrapper) => {
      const $button = $(wrapper).find('button');
      $button.on('click', function () {
        if (!$(wrapper).hasClass('active')) {
          $(block)
            .find('.block-wrapper.active')
            .each((i, el) => {
              $(el).removeClass('active');
              $(el).find('.item-wrapper').slideUp();
            });
          $(wrapper).addClass('active');
        } else {
          $(wrapper).removeClass('active');
        }
        $(wrapper).find('.item-wrapper').slideToggle();
      });
    });
  });
}

initCategories();
