let $template = $('body.page-template-page-forre-a-aa');

if($template) {
    let $letterLinks = $template.find('.letter-links a');
    let $alphabetLinks = $template.find('.alphabet-filter a');
    $alphabetLinks.click(function(e) {
        e.preventDefault();
        scrollToAnchor(this.href, e, 100);
    });
    $letterLinks.click(function(e) {
        e.preventDefault();
        let $slug = $(this).attr('id');
        if(!$(this).hasClass('active')) {
            $letterLinks.removeClass('active');
            $(this).addClass('active');
        }
        else {
            $(this).removeClass('active');
        }
        let $container = $(this).parents('.letter').find('.expand-container');
        let $content = $container.find('.expand-content#expand-' + $slug);
        if($content.hasClass('show')) {
            $container.removeClass('active');
            setTimeout(function() {
                $content.removeClass('show');
            }, 500);
        }
        else {
            $container.find('.expand-content').removeClass('show');
            $container.addClass('active');
            $content.addClass('show');
        }
    });
}

function scrollToAnchor(href, event, offset) {
    if (href.length <= 1) {
        return;
    }

    var hashIndex = href.indexOf('#');

    if (hashIndex === -1) {
        return;
    }

    var id = decodeURI(href.substring(hashIndex + 1));
    if (!id) {
        return;
    }

    var $el = $('#' + id);

    if ($el.length === 0){
        return;
    }

    $('html, body').animate({
        scrollTop: $el.offset().top - (offset || 0)
    }, 300);

    if (event) {
        event.preventDefault();
        window.location.hash = '';
    }
}